<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-xx-lg-2 col-x-lg-2 col-lg-3 col-xs-12">
          <router-link to="/" class="footer__logo">
            <img src="~@/assets/img/footer-logo.png" alt/>
          </router-link>
          <div class="footer__copyright" v-if="!getResponsive">
            ©ASTRAZENECA {{ year }}
          </div>
        </div>
        <div class="col-xx-lg-6 col-x-lg-7 col-lg-9 col-xs-12">
          <div class="footer__info">
            Лікарські засоби мають протипоказання та можливі побічні реакції.
            Лікарські засоби слід застосовувати тільки за призначенням лікаря та
            після консультації лікаря. UA-6376 Approved February 2025.
          </div>
          <nav class="footer__nav">
            <a
              href="https://www.astrazeneca.ua/legal-information.html"
            >правові положення та умови використання</a>
            <a target="_blank" href="https://www.azprivacy.astrazeneca.com/europe/ukraine/ru/privacy-notices.html"
            >Політика конфіденційності</a
            >
            <a href="/Pharmacy_Instruction.pdf" target="_blank">ПІДКЛЮЧЕННЯ АПТЕК ДО ПРОГРАМИ</a>

            <router-link to="/terms-and-conditions"
            >Правила участі у програмі
            </router-link
            >
            <router-link to="/policy"
            >Політика конфіденційності Програми
            </router-link>

          </nav>
        </div>
        <div
          class="col-xx-lg-4 col-xx-lg-offset-0 col-x-lg-3 col-x-lg-offset-0 col-lg-9 col-lg-offset-3 col-xs-12"
        >
          <div class="footer__options">
            <div class="footer__copyright" v-if="getResponsive">
              ©ASTRAZENECA {{ year }}
            </div>
            <router-link
              to="/adverse-reaction"
              class="footer__btn"
              v-if="!getResponsive"
              @click.native="adverseReactionClick()"
            >Повідомити про побічну реакцію
            </router-link
            >
            <div class="footer__designed-by">
              designed by
              <a href="https://www.inchkiev.ua" target="_blank">INCHKYЇV</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="popup-wrapper" v-if="popup">
      <div class="popup">
        <p>Документ в стадії розробки</p>
        <div class="btn-footer" @click="popup = false">Добре</div>
      </div>
    </div>
  </footer>
</template>
<script>
import {mapGetters} from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapGetters(["getResponsive"])
  },
  data() {
    return {
      popup: false,
      year: new Date().getFullYear()
    };
  },

  methods: {
    adverseReactionClick() {
      // eslint-disable-next-line no-undef
      gtag("event", "click", {event_category: "click_button_side_reaction"}); //[Кнопка "Повідомити про побічну реакцію"(футер)]
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-wrapper {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;

  width: 100%;
  height: 100%;
  background: rgba(105, 182, 199, 0.9);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;

  .popup {
    background: white;
    padding: 20px 40px;
    border: 1px solid #3c1053;


    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 22px;

      font-weight: 400;
      margin-bottom: 20px;
      color: rgb(60, 16, 83);
      text-align: center;
    }
  }

  .btn-footer {
    display: inline-flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.07em;
    text-transform: uppercase;

    background: #FFD770;
    border: 1px solid #F0AB00;
    border-radius: 2px;

    color: #3C1053;
    font-size: 12px;
    line-height: 19px;
    font-weight: 700;
    padding: 16px 24px;
    cursor: pointer;

    &:hover {
      background: #f0ab00;
      border: 1px solid #3c1053;
      color: #3c1053;
    }
  }
}

</style>
