<template>
  <div class="modal hotline" v-show="getHotlineModal">
    <div class="modal__content" >
      <div class="modal__close" @click="setHotline(false)"></div>
      <h3 class="modal__title">Шановні пацієнти,</h3>
      <div class="modal__main">
        <div class="modal__text">
          Інформуємо, що у зв’язку з обмеженнями, встановленими Постановою КМУ №168 від 14.02.2025р., дія програми «ТерапіяПЛЮС» буде вимушено призупинена з 25.02.2025р.
          <br>
          У разі відновлення програми, відповідну інформацію буде розміщено на сайті програми.
        </div>
        <div class="modal__options">
          <a
            href="#"
            class="modal__btn modal__btn_yellow"
            @click="setHotline(false)"
            >Зрозуміло</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Hotline",
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getHotlineModal"])
  },
  methods: {
    ...mapMutations(["setHotline"])
  },
  watch: {
    getHotlineModal: function() {}
  }
};
</script>
<style>
.hotline{
  z-index: 100000000000 !important;
}
</style>
